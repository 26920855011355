import jwtDecode from 'jwt-decode';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { authApi } from '../api/authApi';
import { clientApi } from '../api/clientApi';
import { deviceApi } from '../api/deviceApi';
import { serviceClientApi } from '../api/serviceClientApi';
import { userApi } from '../api/userApi';
import type { User } from '../types/user';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  is2FAEnabled: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    is2FAEnabled?: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  is2FAEnabled: false,
  isInitialized: false,
  user: null
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, is2FAEnabled, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      is2FAEnabled,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      is2FAEnabled: user?.is_2fa_enabled,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    is2FAEnabled: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      is2FAEnabled: user?.is_2fa_enabled,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const currentTime = Date.now() / 1000;

  const decoded: any = jwtDecode(accessToken);

  return decoded.exp > currentTime;
};

const setSession = (data: any) => {
  if (!data) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  } else {
    const accessToken = data.access_token;
    const refreshToken = data.refresh_token;
    if (accessToken || refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    }
  }
};

const getAllRegisters = async (): Promise<void> => {
  try {
    // const registers1 = await deviceApi.getAllRegister1();
    // const register2 = await deviceApi.getAllRegister2();
    const allRegisters = await deviceApi?.getAllModbusRegisters();
    localStorage.setItem('registers', JSON.stringify(allRegisters));
    // const registers = [...registers1, ...register2];
    // localStorage.setItem('registers', JSON.stringify(registers));
  } catch (error) {
    console.log(error);
  }
};

// eslint-disable-next-line consistent-return
const getPermissions = async (accessToken: string): Promise<void> => {
  try {
    const permissions: any = await userApi.getUserPermissions(accessToken);
    return permissions?.permissions;
  } catch (error) {
    const locale = localStorage.getItem('locale');
    localStorage.clear();
    localStorage.setItem('locale', locale);
    window.location.reload();
  }
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', 'nl');
  }

  useEffect(() => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: false,
        is2FAEnabled: false,
        user: null,
      }
    });
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');
        if (!window.localStorage.getItem('registers') && isValidToken(accessToken)) {
          getAllRegisters();
        }
        const userLocal: any = JSON.parse(window.localStorage.getItem('user'));

        // await userApi.updateUserLastSeen();

        if (isValidToken(accessToken)) {
          // setInterval(async () => {
          //   await userApi.updateUserLastSeen();
          // }, 30 * 60 * 1000);

          const permissions = await getPermissions(accessToken);
          const user: any = await authApi.me(accessToken, permissions, userLocal?.is_2fa_enabled);
          if (localStorage.getItem('redirect_to') && !isUndefined(localStorage.getItem('redirect_to'))) {
            userLocal.is_2fa_enabled = false;
          }
          await dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              is2FAEnabled: userLocal?.is_2fa_enabled,
              user
            }
          });
        } else if (window.location.pathname.indexOf('login') !== -1 && !accessToken) {
          console.log('login');
        } else if (window.location.pathname.indexOf('forgot-password') !== -1) {
          console.log('forgot-password');
        } else if (window.location.pathname.indexOf('reset-password') !== -1) {
          console.log('reset-password');
        } else if (window.location.pathname.indexOf('/login') !== -1 && !accessToken) {
          window.location.href = '/login';
        } else if (refreshToken && !isValidToken(accessToken)) {
          try {
            const keycloakData = await authApi.refreshSession(refreshToken);
            if (keycloakData) {
              setSession(keycloakData);
              window.location.reload();
            }
          } catch (error) {
            const locale = localStorage.getItem('locale');
            localStorage.clear();
            localStorage.setItem('locale', locale);
            window.location.reload();
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              is2FAEnabled: false,
              user: null,
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            is2FAEnabled: false,
            user: null,
          }
        });
      }
    };

    initialize();
  }, []);

  const updateUserLocalStorage = async (decoded, user, keycloakData, permissions) => {
    const loggedUser = await authApi.me(keycloakData.access_token, permissions, keycloakData?.is_2fa_enabled);
    localStorage.setItem('user', JSON.stringify(user));
    const roles = decoded?.realm_access?.roles;
    const isClientUserRegex = new RegExp('^client_');
    const isServiceClientUserRegex = new RegExp('^service_client_');
    const isSubClientUserRegex = new RegExp('^sub_client_');
    const isClientUser = roles?.find((role) => (
      isClientUserRegex?.test(role)
    ));
    const isServiceClientUser = roles?.find((role) => (
      isServiceClientUserRegex?.test(role) || isSubClientUserRegex?.test(role)
    ));

    if (isClientUser?.length > 0) {
      const client: any = await clientApi.getById(+user?.client_id);
      localStorage.setItem('client', JSON.stringify(client?.data));
      if (isClientUser?.length > 0) {
        localStorage.setItem('client_logo', client?.data?.logo);
      }
    } else if (isServiceClientUser?.length > 0) {
      const client: any = await serviceClientApi.getById(+user?.client_id);
      localStorage.setItem('client', JSON.stringify(client?.data));
      if (isServiceClientUser?.length > 0) {
        localStorage.setItem('client_logo', client?.data?.logo);
      }
    } else {
      localStorage.removeItem('client');
    }
    dispatch({
      type: 'LOGIN',
      payload: {
        user: loggedUser,
      }
    });
  };

  const login = async (email: string, password: string): Promise<void> => {
    const keycloakData: any = await authApi.login({ email, password });
    let permissions: any = await userApi.getUserPermissions(keycloakData.access_token);
    permissions = permissions?.permissions;
    if (keycloakData) {
      setSession(keycloakData);
      const decoded: any = jwtDecode(keycloakData.access_token);
      const user: any = await userApi.getByKeycloakId(decoded?.sub);
      user.is_2fa_enabled = keycloakData?.is_2fa_enabled;
      if (!user?.is_email_verified && user?.is_activated) {
        const locale = localStorage.getItem('locale');
        localStorage.clear();
        localStorage.setItem('locale', locale);
        localStorage.setItem('tempAccessToken', keycloakData.access_token);
        window.location.href = `/reset-password/${decoded?.sub}`;
      } else if (user.is_2fa_enabled) {
        await updateUserLocalStorage(decoded, user, keycloakData, permissions);
        window.location.href = '/two-factor-authentication';
      } else if (user?.is_activated) {
        await updateUserLocalStorage(decoded, user, keycloakData, permissions);
      } else {
        const locale = localStorage.getItem('locale');
        localStorage.clear();
        localStorage.setItem('locale', locale);
        throw new Error('Account is deactivated');
      }
    }
  };

  const logout = async (): Promise<void> => {
    const locale = localStorage.getItem('locale');
    localStorage.clear();
    localStorage.setItem('locale', locale);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (): Promise<void> => {
    await authApi.register();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
