import type { Theme } from '@material-ui/core';
import { Box, Button, CircularProgress, Divider, Drawer, Link, Radio, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import ClientIcon from 'src/icons/Client';
import CommissioningIcon from 'src/icons/Commissioning';
import DashboardIcon from 'src/icons/Dashboard';
import DeviceIcon from 'src/icons/Device';
// import ProjectIcon from 'src/icons/Project';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import jwtDecode from 'jwt-decode';
import { isObject, isUndefined } from 'lodash';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { deviceApi } from 'src/api/deviceApi';
import { userApi } from 'src/api/userApi';
import useAuth from 'src/hooks/useAuth';
import ReportIcon from 'src/icons/Report';
import type { DevicesCount } from 'src/types/device';
import logger from 'src/utils/logger';
import { v4 as uuidv4 } from 'uuid';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .item-0': {
      display: 'none'
    },
    '& .MuiRadio-root': {
      padding: '0'
    },
  },
  divider: {
    marginBottom: '1rem',
    // borderBottom: 1,
  },
  sendGridWrapper: {
    background: 'rgba(255, 87, 86, .2) !important',
    padding: '10px',
    borderRadius: '10px',
  },
  sendGridTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgb(255, 87, 86) !important'
  },
  maxitelUpdatedAt: {
    fontSize: 12,
    textAlign: 'right',
    marginTop: 8,
    fontStyle: 'italic',
  },
  maxitelTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  maxitelSubTitle: {
    fontSize: '16px',
    fontWeight: 300,
  },
  countersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  maxitelWrapper: {
    border: '1px solid #E6E6E6',
    backgroundColor: '#F6F6F6',
    padding: '10px',
    paddingBottom: '18px',
    borderRadius: '10px',
  },
  counter: {
    padding: 4,
    position: 'relative',
    top: '-10px',
    marginBottom: 10,
    color: 'white',
    minWidth: 30,
    borderRadius: '50%',
    textAlign: 'center',
    paddingRight: 5,
    right: 18,
  },
  totalDevicesCounter: {
    color: 'rgb(0, 102, 153, 1) !important',
    backgroundColor: 'rgba(0, 102, 153,.2)',
  },
  onlineDevicesCounter: {
    color: 'rgb(76, 175, 80) !important',
    backgroundColor: 'rgb(232, 245, 233)',
  },
  offlineDevicesCounter: {
    color: 'rgb(255, 87, 86) !important',
    backgroundColor: 'rgba(255, 87, 86, .2)',
  },
  errorDevicesCounter: {
    color: 'rgba(246, 165, 16, 1) !important',
    backgroundColor: 'rgba(246, 165, 16, .2)',
  },
  onlineMaxitel: {
    padding: '6px',
    borderRadius: '10px',
    backgroundColor: 'rgba(76, 175, 80, .2) !important',
  },
  warningMaxitel: {
    padding: '6px',
    borderRadius: '10px',
    backgroundColor: 'rgba(246, 165, 16, .2) !important',
  },
  errorMaxitel: {
    padding: '6px',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 87, 86, .2) !important',
  },
  warningMaxitelText: {
    color: 'rgba(246, 165, 16, 1) !important',
  },
  errorMaxitelText: {
    color: 'rgba(255, 87, 86, 1) !important',
  },
  onlineMaxitelText: {
    color: 'rgb(76, 175, 80) !important',
  },
  unProvisionedDevicesCounter: {
    color: 'rgb(89, 99, 95, 1) !important',
    backgroundColor: 'rgba(167,176,172,.2) !important',
  },
  profileWrapper: {
    width: 215,
    margin: '15px auto 0',
    backgroundColor: 'white',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      '& $editButton': {
        display: 'flex',
        width: '100%',
        height: '100%',
        color: '#121037',
        borderRadius: 0,
        backgroundColor: 'rgba(255, 255, 255, .75)',
        [theme.breakpoints.down('sm')]: {
          width: '50% !important',
        },
      },
      '& $deleteButton': {
        display: 'flex',
        color: '#DD0000',
        width: '100%',
        borderRadius: 0,
        backgroundColor: 'rgba(255, 255, 255, .75)',
        [theme.breakpoints.down('sm')]: {
          width: '50% !important',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  profileImage: {
    width: 120,
    height: 120,
    margin: '0 auto',
    display: 'flex',
    borderRadius: '50%',
    border: '1px solid #f9f8f8',
    objectFit: 'contain',
  },
  profileButtonWrapper: {
    position: 'absolute',
    top: 0,
    left: '22%',
    right: '50%',
    bottom: 0,
    height: 120,
    width: 120,
    opacity: 0,
    transition: '.5s ease',
    backgroundColor: 'rgba(255, 255, 255, .75)',
    '&:hover': {
      opacity: 0.5,
    },
  },
  editButton: {
    display: 'none',
  },
  deleteButton: {
    display: 'none',
  },
  buttonText: {
    marginLeft: 10,
    textTransform: 'capitalize',
    fontSize: '.8rem',
  },
  deleteText: {
    color: '#DD0000',
    fontSize: '.8rem',
  },
  userName: {
    textAlign: 'center',
  },
  loadingWrapper: {
    marginTop: 20,
    textAlign: 'center',
  },
  loadingCircle: {
    color: theme.palette.primary.main,
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
  hideNavSection: {
    display: 'none',
  }
}));

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const { user } = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const year = moment().format('YYYY');
  const dutchTimeZoneHours = moment().isBetween(`${year}-03-31`, `${year}-10-27`) ? 2 : 1;
  const [profileImage, setProfileImage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [checkEmailLimitExceeded, setCheckEmailLimitExceeded] = useState<boolean>(false);
  const [devicesCount, setDevicesCount] = useState<DevicesCount>();
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const decoded: any = jwtDecode(localStorage.getItem('accessToken'));
  const roles = decoded?.realm_access?.roles;
  const isFortesAdmin = roles?.includes('fortes_admin');
  const isClientUserRegex = new RegExp('^client_');
  const isServiceClientUserRegex = new RegExp('^service_client_');
  const isSubClientUserRegex = new RegExp('^sub_client_');
  let isClientUser = roles?.find((role) => (
    isClientUserRegex?.test(role)
  ));
  let isServiceClientUser = roles?.find((role) => (
    isServiceClientUserRegex?.test(role) || isSubClientUserRegex?.test(role)
  ));

  isClientUser = isClientUser?.length > 0;
  isServiceClientUser = isServiceClientUser?.length > 0;

  const domain = `${process.env.REACT_APP_IMAGE_UPLOAD_URL}/images/user/`;
  const userToken = JSON.parse(localStorage.getItem('user'));

  const handlePicture = async (e) => {
    try {
      setIsSubmitting(true);
      const image = await userApi.uploadFile(e.target.files[0], 'user', profileImage);
      setProfileImage(image?.fileName);
      localStorage.setItem('profile_image', image?.fileName);
      const userObj = JSON.parse(localStorage.getItem('user'));
      userObj.profile_image = image?.fileName;
      localStorage.setItem('user', JSON.stringify(userObj));
      await userApi.updateProfileImageByKeyCloakId(decoded?.sub, image?.fileName);
      setIsSubmitting(false);
      const msg = t('image_upload_success');
      toast.dismiss();
      toast.success(msg, {
        duration: 10000,
      });
    } catch (err) {
      setIsSubmitting(false);
      logger(err, 'error');
      const errorMsg = err?.response?.status === 400 ? t('image_upload_file_type_error') : t('image_upload_file_size_error');
      toast.dismiss();
      toast.error(errorMsg, {
        duration: 10000,
      });
    }
  };

  const emailLimitCheck = async () => {
    try {
      const res: any = await userApi.checkEmailLimitExceeded();
      setCheckEmailLimitExceeded(res?.limit_exceeded);
    } catch (err) {
      logger(err, 'error');
    }
  };

  const getUserByKeyCloakId = async () => {
    const keyCloakUser = await userApi.getByKeycloakId(decoded?.sub);
    localStorage.setItem('profile_image', keyCloakUser?.profile_image);
    setProfileImage(keyCloakUser?.profile_image);
  };

  const [activeMaxitelStatus, setActiveMaxitelStatus] = useState<any>({});

  const [maxitelStatus, setMaxitelStatus] = useState<any>({
    device_monitoring_maxitel_mobile_data_network_purple: 'device_monitoring_maxitel_operational_status',
    device_monitoring_maxitel_mobile_m2m_blend: 'device_monitoring_maxitel_operational_status',
    device_monitoring_maxitel_mobile_on_cloud_network_green: 'device_monitoring_maxitel_operational_status',
    device_monitoring_maxitel_mobile_on_cloud_network_purple: 'device_monitoring_maxitel_operational_status',
    device_monitoring_maxitel_other_api: 'device_monitoring_maxitel_operational_status',
    device_monitoring_maxitel_other_portal: 'device_monitoring_maxitel_operational_status',
  });

  const getMaxitelStatus = async () => {
    try {
      const res = await deviceApi.getMaxitelStatus();
      setMaxitelStatus(res?.data);
      const count: any = Object.values(res?.data)?.reduce((value: any, value2: any) => (
        // eslint-disable-next-line no-sequences
        value[value2] ? ++value[value2] : (value[value2] = 1),
        value
      ), {});

      setActiveMaxitelStatus(count);
    } catch (e) {
      logger(e, 'error');
    }
  };

  useEffect(() => {
    getMaxitelStatus();
  }, []);

  const getDevicesCount = async () => {
    try {
      const res = await deviceApi.getDevicesCount();
      setDevicesCount(res);
    } catch (err) {
      logger(err, 'error');
    }
  };

  useEffect(() => {
    if (localStorage.getItem('user')) {
      const userObj = JSON.parse(localStorage.getItem('user'));
      setProfileImage(userObj?.profile_image);
    } else {
      getUserByKeyCloakId();
    }
    getDevicesCount();
    emailLimitCheck();
  }, []);

  const ref = useRef(null);

  const handleFileWindow = () => {
    const element: HTMLElement = document.getElementById('profilePicture') as HTMLElement;
    element.click();
  };

  const clientOverviewChildren = [];

  if (isServiceClientUser) {
    clientOverviewChildren.push({
      title: t('sidebar_service_clients'),
      path: isServiceClientUser ? `/admin/service_client/${userToken?.client_id}` : '/admin/service_client',
      viewable: user?.permissions?.includes('sidebar-service-client-read'),
    });
  } else {
    clientOverviewChildren.push({
      title: t('sidebar_clients'),
      path: isClientUser ? `/admin/client/${userToken?.client_id}` : '/admin/client',
      viewable: user?.permissions?.includes('sidebar-client-read'),
    },
    {
      title: t('sidebar_service_clients'),
      path: isServiceClientUser ? `/admin/service_client/${userToken?.client_id}` : '/admin/service_client',
      viewable: user?.permissions?.includes('sidebar-service-client-read'),
    });
  }

  const commissioningOverview = {
    items: [
      {
        title: t('sidebar_commissioning_overview'),
        path: '/admin/commissioning',
        icon: <CommissioningIcon fontSize="small" />,
        viewable: user?.permissions?.includes('sidebar-commissioning-management-tab-read'),
        children: [
          {
            title: t('sidebar_commissioning'),
            path: '/admin/commissioning',
            viewable: user?.permissions?.includes('sidebar-commissioning-read'),
          },
          // {
          //   title: t('Commissioning Wizard'),
          //   path: '/admin/wizard_for_commissioning',
          //   viewable: isFortesAdmin,
          // },
          {
            title: t('sidebar_swapping'),
            path: '/admin/swapping',
            viewable: user?.permissions?.includes('sidebar-swapping-read'),
          },
          {
            title: t('sidebar_clear_device'),
            path: '/admin/clear_device',
            viewable: user?.permissions?.includes('sidebar-clear-device-read'),
          },
        ]
      },
    ]
  };

  const sections = [
    {
      items: [
        {
          title: t('sidebar_dashboard'),
          path: '/dashboard',
          icon: <DashboardIcon fontSize="small" />,
          viewable: user?.permissions?.includes('sidebar-dashboard-read'),
        },
      ]
    },
    {
      items: [
        {
          title: t('sidebar_clients_overview'),
          path: '/admin/client',
          icon: <ClientIcon fontSize="small" />,
          children: clientOverviewChildren,
          viewable: user?.permissions?.includes('sidebar-client-management-tab-read'),
        },
      ]
    },
    {
      items: [
        {
          title: t('sidebar_devices_overview'),
          path: ['/admin/device', '/admin/project'],
          icon: <DeviceIcon fontSize="small" />,
          viewable: user?.permissions?.includes('sidebar-device-read'),
          children: [
            {
              title: t('sidebar_devices'),
              path: '/admin/device',
              viewable: user?.permissions?.includes('sidebar-device-read'),
            },
            {
              title: t('sidebar_projects'),
              path: '/admin/project',
              viewable: user?.permissions?.includes('sidebar-project-read'),
            },
          ]
        },
      ]
    },
    commissioningOverview,
    {
      items: [
        {
          title: t('sidebar_reporting_overview'),
          path: '/admin/reporting_menu',
          icon: <ReportIcon fontSize="small" />,
          viewable: user?.permissions?.includes('sidebar-reporting-management-read'),
        },
      ]
    },
    {
      items: [
        {
          title: t('sidebar_fortes_settings'),
          path: '/admin/setting_menu',
          icon: <ClientIcon fontSize="small" />,
          viewable: user?.permissions?.includes('sidebar-settings-tab-read'),
        },
      ]
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      className={classes.root}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 1,
        pt: 0,
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 1,
            pt: 0,
            pb: 0,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Logo
            sx={{
              height: 40,
              width: 40
            }}
          />
        </Box>
        <Box sx={{ p: 1 }}>
          <form>
            <Box
              className={classes.profileWrapper}
              component="div"
            >
              {isSubmitting ? (
                <div
                  className={classes.loadingWrapper}
                >
                  {t('image_uploading_text')}
                  &nbsp;&nbsp;
                  <CircularProgress className={classes.loadingCircle} />
                </div>
              ) : (
                <>
                  <img
                    className={classes.profileImage}
                    src={profileImage === 'dummy_image.png' ? `https://assets.it22.nl/fortes-energy/admin/${profileImage}` : `${domain}${profileImage}`}
                    alt="Default Avatar"
                  />
                  <Box
                    className={classes.profileButtonWrapper}
                    component="div"
                    onClick={handleFileWindow}
                  >
                    <Button className={classes.editButton}>
                      <>
                        <input
                          ref={ref}
                          accept="image/jpg,image/jpeg,image/png,.pdf,.doc,.docx"
                          type="file"
                          id="profilePicture"
                          name="file"
                          onChange={handlePicture}
                          hidden
                        />
                        <div style={{ display: 'flex' }}>
                          <svg
                            style={{
                              position: 'relative',
                              top: 2,
                              width: 13,
                            }}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.2968 8.65963C13.9005 8.65963 13.5801 8.98074 13.5801 9.37626V15.1096C13.5801 15.5045 13.259 15.8262 12.8635 15.8262H2.83017C2.43453 15.8262 2.11355 15.5045 2.11355 15.1096V5.07626C2.11355 4.68141 2.43453 4.35964 2.83017 4.35964H8.56354C8.95984 4.35964 9.28016 4.03854 9.28016 3.64302C9.28016 3.24738 8.95984 2.92627 8.56354 2.92627H2.83017C1.64481 2.92627 0.680176 3.89091 0.680176 5.07626V15.1096C0.680176 16.295 1.64481 17.2596 2.83017 17.2596H12.8635C14.0489 17.2596 15.0135 16.295 15.0135 15.1096V9.37626C15.0135 8.97996 14.6931 8.65963 14.2968 8.65963Z"
                              fill="#000"
                            />
                            <path
                              d="M7.39915 8.00667C7.34902 8.0568 7.31529 8.12058 7.30099 8.18934L6.79433 10.7236C6.77071 10.841 6.80798 10.9621 6.89249 11.0474C6.96059 11.1155 7.05232 11.152 7.14628 11.152C7.16911 11.152 7.19286 11.1499 7.21648 11.1449L9.74992 10.6383C9.82012 10.6238 9.8839 10.5902 9.93337 10.54L15.6036 4.86973L13.0702 2.33643L7.39915 8.00667Z"
                              fill="#000"
                            />
                            <path
                              d="M17.3556 0.58414C16.6569 -0.114635 15.5202 -0.114635 14.8221 0.58414L13.8303 1.57594L16.3638 4.10938L17.3556 3.11744C17.6939 2.77993 17.8802 2.32983 17.8802 1.85112C17.8802 1.37241 17.6939 0.922307 17.3556 0.58414Z"
                              fill="#000"
                            />
                          </svg>
                        </div>
                      </>
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </form>
          <Box
            mt={2}
            mb={1}
          >
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.userName}
            >
              {user.name}
            </Typography>
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box sx={{ p: 2 }}>
          {sections?.map((section) => (
            <NavSection
              key={uuidv4()}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 0
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider
          className={clsx(classes.divider, {
            [classes.hideNavSection]: !user?.permissions?.includes('side-device-counters-read')
          })}
        />
        <Box
          sx={{ p: 1 }}
          className={clsx({
            [classes.hideNavSection]: !user?.permissions?.includes('side-device-counters-read')
          })}
        >
          <Box className={classes.countersWrapper}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {t('sidebar_total_devices')}
            </Typography>
            <Typography
              className={clsx(classes.counter, classes.totalDevicesCounter)}
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {
                (!isUndefined(devicesCount) ? Object.values(devicesCount)?.reduce((accumulator, currentValue) => (
                  !Number.isNaN(parseInt(currentValue, 10)) ? accumulator + currentValue : accumulator
                ), 0) : 0)
              }
            </Typography>
          </Box>

          <Box className={classes.countersWrapper}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {t('sidebar_online_devices')}
            </Typography>
            <Typography
              className={clsx(classes.counter, classes.onlineDevicesCounter)}
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {isObject(devicesCount) ? devicesCount?.devicesOnlineCounter + devicesCount?.deviceErrorCounter : 0}

            </Typography>
          </Box>

          <Box className={classes.countersWrapper}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {t('sidebar_offline_devices')}
            </Typography>
            <Typography
              className={clsx(classes.counter, classes.offlineDevicesCounter)}
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {isObject(devicesCount) ? devicesCount?.deviceOfflineCounter : 0}

            </Typography>
          </Box>

          <Box className={classes.countersWrapper}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {t('sidebar_error_devices')}
            </Typography>
            <Typography
              className={clsx(classes.counter, classes.errorDevicesCounter)}
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {isObject(devicesCount) ? devicesCount?.deviceErrorCounter : 0}
            </Typography>
          </Box>
          <Box className={classes.countersWrapper}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {t('sidebar_unprovisioned_devices')}
            </Typography>
            <Typography
              className={clsx(classes.counter, classes.unProvisionedDevicesCounter)}
              color="textSecondary"
              variant="subtitle2"
              sx={{
                pb: 1
              }}
            >
              {isObject(devicesCount) ? devicesCount?.deviceUnprovisionedCounter : 0}
            </Typography>
          </Box>
        </Box>

        {(checkEmailLimitExceeded && isFortesAdmin) && (
          <Box sx={{ p: 1, mb: 1 }}>
            <Box className={classes.sendGridWrapper}>
              <Typography
                color="textSecondary"
                variant="h6"
                className={classes.sendGridTitle}
                sx={{
                  paddingBottom: '2px'
                }}
              >
                {t('device_monitoring_send_grid_email_reached')}
              </Typography>
            </Box>
          </Box>
        )}

        <Box sx={{ p: 1, mb: 1 }}>
          <Box className={classes.maxitelWrapper}>
            <Typography
              color="textSecondary"
              variant="h6"
              className={classes.maxitelTitle}
              sx={{
                paddingBottom: '2px'
              }}
            >
              {t('device_monitoring_maxitel_status_heading')}
              <Radio
                checked
                className={clsx({
                  [classes.warningMaxitelText]: activeMaxitelStatus?.device_monitoring_maxitel_operational_status >= 4 && activeMaxitelStatus?.device_monitoring_maxitel_operational_status < 6,
                  [classes.errorMaxitelText]: activeMaxitelStatus?.device_monitoring_maxitel_operational_status >= 0 && activeMaxitelStatus?.device_monitoring_maxitel_operational_status < 4,
                  [classes.onlineMaxitelText]: activeMaxitelStatus?.device_monitoring_maxitel_operational_status === 6,
                })}
              />
            </Typography>
            <Typography
              color="textSecondary"
              variant="h6"
              className={classes.maxitelTitle}
              sx={{
                pb: 1,
                fontWeight: 300,
                borderBottom: '1px solid #E6E6E6'
              }}
            >
              {t('device_monitoring_maxitel_status_online')}
              <Typography
                className={clsx({
                  [classes.warningMaxitelText]: activeMaxitelStatus?.device_monitoring_maxitel_operational_status >= 4 && activeMaxitelStatus?.device_monitoring_maxitel_operational_status < 6,
                  [classes.errorMaxitelText]: activeMaxitelStatus?.device_monitoring_maxitel_operational_status >= 0 && activeMaxitelStatus?.device_monitoring_maxitel_operational_status < 4,
                  [classes.onlineMaxitelText]: activeMaxitelStatus?.device_monitoring_maxitel_operational_status === 6,
                })}
                variant="h6"
                sx={{
                  paddingBottom: '2px'
                }}
              >
                {activeMaxitelStatus?.device_monitoring_maxitel_operational_status}
                /6
              </Typography>
            </Typography>
            <div style={{ paddingTop: '12px' }} />
            <Typography
              color="textSecondary"
              variant="h6"
              className={clsx(classes.maxitelTitle, classes.maxitelSubTitle, {
                [classes.onlineMaxitel]: maxitelStatus?.device_monitoring_maxitel_mobile_m2m_blend === 'device_monitoring_maxitel_operational_status',
                [classes.errorMaxitel]: maxitelStatus?.device_monitoring_maxitel_mobile_m2m_blend !== 'device_monitoring_maxitel_operational_status',
              })}
              sx={{
                paddingBottom: '2px'
              }}
            >
              {t('device_monitoring_maxitel_mobile_m2m_blend')}
              <Radio
                checked
                className={clsx(classes.maxitelTitle, classes.maxitelSubTitle, {
                  [classes.onlineMaxitelText]: maxitelStatus?.device_monitoring_maxitel_mobile_m2m_blend === 'device_monitoring_maxitel_operational_status',
                  [classes.errorMaxitelText]: maxitelStatus?.device_monitoring_maxitel_mobile_m2m_blend !== 'device_monitoring_maxitel_operational_status',
                })}
              />
            </Typography>
            <div style={{ paddingTop: '8px' }} />
            <Typography
              color="textSecondary"
              variant="h6"
              className={clsx(classes.maxitelTitle, classes.maxitelSubTitle, {
                [classes.onlineMaxitel]: maxitelStatus?.device_monitoring_maxitel_other_api === 'device_monitoring_maxitel_operational_status',
                [classes.errorMaxitel]: maxitelStatus?.device_monitoring_maxitel_other_api !== 'device_monitoring_maxitel_operational_status',
              })}
              sx={{
                paddingBottom: '2px'
              }}
            >
              {t('device_monitoring_maxitel_other_api')}
              <Radio
                checked
                className={clsx(classes.maxitelTitle, classes.maxitelSubTitle, {
                  [classes.onlineMaxitelText]: maxitelStatus?.device_monitoring_maxitel_other_api === 'device_monitoring_maxitel_operational_status',
                  [classes.errorMaxitelText]: maxitelStatus?.device_monitoring_maxitel_other_api !== 'device_monitoring_maxitel_operational_status',
                })}
              />
            </Typography>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              sx={{
                paddingBottom: '2px'
              }}
              className={classes.maxitelUpdatedAt}
            >
              {t('device_monitoring_maxitel_updated_at')}
              :&nbsp;
              {moment(maxitelStatus?.updatedAt?.replace('T', ' ')?.substring(0, 19))?.add(dutchTimeZoneHours, 'hours')?.format('DD MMM, YYYY HH:mm')}
            </Typography>
          </Box>
        </Box>

        {user?.permissions?.includes('sidebar-change-logs-read') && (
          <>
            <Box sx={{ p: 1 }}>
              <Box className={classes.countersWrapper}>
                <Link
                  color="inherit"
                  component={RouterLink}
                  to="/admin/change_logs"
                  variant="subtitle2"
                  // className={classes.linkButton}
                >
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{
                      pb: 1
                    }}
                  >
                    {t('sidebar_change_logs')}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </>
        )}
        {isFortesAdmin && (
          <>
            <Divider className={classes.divider} />
            <Box sx={{ p: 1 }}>
              <Box className={classes.countersWrapper}>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  sx={{
                    pb: 1
                  }}
                >
                  {t('sidebar_version_title')}
                  &nbsp;
                  { process.env.REACT_APP_VERSION }
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: '100% !important',
            top: '0 !important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
