import type { AppBarProps } from '@material-ui/core';
import { AppBar, Box, FormControlLabel, IconButton, Switch, Toolbar, Typography } from '@material-ui/core';
import { experimentalStyled, makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import MenuIcon from 'src/icons/Menu';
import AccountPopover from './AccountPopover';
import ContentSearch from './ContentSearch';
import DashboardSidebar from './DashboardSidebar';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import clsx from 'clsx';
import { isUndefined } from 'lodash';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
    position: 'relative'
  },
  menuIcon: {
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      left: 10,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      left: 0,
    },
  },
  monitoring: {
    position: 'absolute',
    left: 300,
    top: 20,
    [theme.breakpoints.down('lg')]: {
      left: 80,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  avgProofSwitch: {
    position: 'absolute',
    left: 308,
    top: 14,
    [theme.breakpoints.down('lg')]: {
      left: 80,
    },
    [theme.breakpoints.down(700)]: {
      display: 'none'
    },
  },
  languageSelector: {
    marginLeft: theme.spacing(1),
    marginRight: '-20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginRight: '0 !important',
      '& button': {
        paddingRight: 0
      },
    },
  },
  notificationsPopover: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      '& button': {
        paddingRight: 0
      },
    },
  },
  searchWrapper: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      display: 'none !important'
    },
  },
}));

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => boolean;
  isSidebarMobileOpen?: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const handleChange = (value): void => {
  if (value) {
    localStorage.setItem('avg_proof_switch', 'true');
  } else {
    localStorage.removeItem('avg_proof_switch');
  }
  window.location.reload();
};

const DashboardNavbar: FC<DashboardNavbarProps> = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);

  return (
    <DashboardNavbarRoot>
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <Toolbar
        sx={{ minHeight: 64 }}
        className={classes.toolbar}
      >
        <IconButton
          className={classes.menuIcon}
          color="inherit"
          onClick={(): void => setIsSidebarMobileOpen(!isSidebarMobileOpen)}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        {/* {(user?.permissions?.includes('monitoring-read') || user?.permissions?.includes('navbar-avg-proof-switch-read')) && (
          <Box
            sx={{
              flexGrow: 1,
              ml: 2
            }}
          />
        )} */}
        {user?.permissions?.includes('navbar-avg-proof-switch-read') && (
          <Box
            sx={{ ml: 1 }}
            className={clsx({
              [classes.avgProofSwitch]: user?.permissions?.includes('navbar-avg-proof-switch-read') && user?.permissions?.includes('monitoring-read'),
              [classes.monitoring]: user?.permissions?.includes('navbar-avg-proof-switch-read') && !user?.permissions?.includes('monitoring-read'),
              [classes.monitoring]: !user?.permissions?.includes('navbar-avg-proof-switch-read') && user?.permissions?.includes('monitoring-read'),
            })}
          >
            <FormControlLabel
              control={(
                <Switch
                  checked={!isUndefined(localStorage.getItem('avg_proof_switch')?.length)}
                  color="secondary"
                  edge="start"
                  name="direction"
                  onChange={(event): void => handleChange(
                    event.target.checked
                  )}
                />
              )}
              label={(
                <Typography
                  component="p"
                  variant="subtitle2"
                >
                  {t('navbar_avg_proof_switch')}
                </Typography>
              )}
            />
          </Box>
        )}
        <Box className={classes.searchWrapper}>
          <ContentSearch />
        </Box>
        <Box className={classes.languageSelector}>
          <LanguagePopover />
        </Box>
        {user?.permissions?.includes('notification-read') && (
          <Box className={classes.notificationsPopover}>
            <NotificationsPopover />
          </Box>
        )}
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
